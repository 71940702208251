import { DonateType, GroupedResponse, IDonate, UnGroupedResponse } from './models/IDonate';
import { IOperator } from './models/IUser';
import { IModel } from './models/IModel';
import { IAdmin } from './store/admin/types';

export function distributePackCounts(messages: string[], maxTotal: number): {
    text: string,
    expectedPackCount: number
}[] {
    const n = messages.length;
    let baseCount = Math.floor(maxTotal / n); // Базовое количество для каждого объекта
    let remainder = maxTotal % n; // Остаток, который необходимо равномерно распределить

    return messages.map((message, index) => {
        // Если есть остаток, добавляем дополнительный к первым объектам
        const extra = index < remainder ? 1 : 0;
        return {
            text: message,
            expectedPackCount: baseCount + extra,
        };
    });
}

export function isGroupedResponse(response: UnGroupedResponse | GroupedResponse): response is GroupedResponse {
    return response.data.length > 0 && 'total' in response.data[0];
}

export function filterDonates(donates: IDonate[], type?: DonateType, buyer?: string, model?: string): IDonate[] {
    return donates.filter((donate) => {
        let res = true

        if (type)
            res = donate.type === type
        if (buyer)
            res = donate.buyerName.toLowerCase().includes(buyer.toLowerCase())
        if (model)
            res = donate.modelUsername === model

        return res
    })
}

export async function copyTextToClipboard(text: string) {
    if ('clipboard' in navigator) {
        try {
            await navigator.clipboard.writeText(text);
            console.log('Текст скопирован в буфер обмена');
        } catch (err) {
            console.error('Не удалось скопировать текст: ', err);
        }
    } else {
        fallbackCopyTextToClipboard(text);
    }
}

function fallbackCopyTextToClipboard(text: string) {
    const textArea = document.createElement('textarea');
    textArea.value = text;

    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        const successful = document.execCommand('copy');
        const msg = successful ? 'успешно' : 'неуспешно';
        console.log(`Текст ${msg} скопирован в буфер обмена через резервный метод`);
    } catch (err) {
        console.error('Не удалось скопировать текст через резервный метод: ', err);
    }

    document.body.removeChild(textArea);
}

export function generatePassword(length: number): string {
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+{}:"<>?[];\',./`~';
    let password = '';
    for (let i = 0, n = charset.length; i < length; ++i) {
        password += charset.charAt(Math.floor(Math.random() * n));
    }
    return password;
}

export function getOperatorUsername(operators: IOperator[]): string {
    if (operators.length === 0) {
        return 'operator1';
    }
    const lastOperator = operators
        .map(op => ({
            username: op.username,
            number: parseInt(op.username.split('operator')[1], 10), // Извлекаем числовую часть и преобразуем в число
        }))
        .sort((a, b) => b.number - a.number)[0]?.username; // Сортируем по числовому значению
    const index = parseInt(lastOperator?.split('operator')[1], 10); // Преобразуем индекс последнего оператора в число
    return `operator${index + 1}`; // Создаем новое имя оператора
}

export const groupByAdmin = (models: IModel[]): {
    admins: IAdmin[],
    groupedModels: { name: string, models: IModel[] }[]
} => {
    const groupedModels = models.reduce((acc, model) => {
        const key = model.admin?.toLowerCase() || 'other';
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(model);
        return acc;
    }, {} as Record<string, IModel[]>);
    const sortedGroups = Object.keys(groupedModels).sort((a, b) => {
        if (a === 'other') return 1;
        if (b === 'other') return -1;
        return 0;
    });
    const admins = sortedGroups.map(key => ({ name: key }));

    const res = sortedGroups.map(key => ({ name: key, models: groupedModels[key] }));

    return {
        admins: admins.sort((a, b) => a.name.localeCompare(b.name)),
        groupedModels: res.sort((a, b) => a.name.localeCompare(b.name))
    };
};
