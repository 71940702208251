import { extendTheme, type ThemeConfig, withDefaultColorScheme } from "@chakra-ui/react";

const config: ThemeConfig = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
}

const theme = extendTheme(withDefaultColorScheme({ colorScheme: 'teal', ...config }))

export default theme
