import { FC, useEffect, useState } from "react";
import { Textarea } from "@chakra-ui/react";
import { useDebounce } from "../hooks/useDebounce";
import { DonateService } from "../services/donate.service";

type Props = {
  comment: string
  buyerId: string
}

const Comment: FC<Props> = ({ comment, buyerId }) => {
  const [value, setValue] = useState(comment);
  const debounceValue = useDebounce(value, 1000);

  useEffect(() => {
    if (debounceValue !== comment)
      DonateService.setComment(buyerId, debounceValue).then()
  }, [debounceValue, buyerId, comment]);
  return (
    <Textarea borderColor="white" minW="300px" focusBorderColor="teal.400" value={value} onChange={e => setValue(e.target.value)} />
  );
};

export default Comment;
