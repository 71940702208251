export const API_URL = "http://79.132.142.97:4000/api";

export const getAuthUrl = (str: string) => `/auth${str}`;

export const getModelUrl = (str: string) => `/models${str}`;

export const getFileUrl = (folder: string) => `/upload${folder ? `?folder=${folder}` : ''}`

export const getMessageUrl = (str: string) => `/messages${str}`

export const getBotUrl = (str: string) => `/bot${str}`

export const getDonateUrl = (str: string) => `/donates${str}`
