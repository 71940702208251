import { FC, useState } from "react";
import {
  Box, Button, FormControl, FormLabel, IconButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter,
  ModalHeader, ModalOverlay, Text, Textarea, Tooltip, useDisclosure, useToast,
} from "@chakra-ui/react";
import { AttachmentIcon, InfoIcon } from "@chakra-ui/icons";
import { BotService } from "../services/bot.service";


const AddParsing: FC<{ modelId: number, onSuccess: () => void }> = ({ modelId, onSuccess }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [celebrity, setCelebrity] = useState("");

  const toast = useToast();

  const closeHandler = () => {
    onClose();
    setCelebrity("");
  };

  const celebrityFormat = celebrity.trim().length === 0 ? [] : celebrity.trim().split("\n")
    .filter(item => item.trim().length);

  const onSubmit = () => {
    if (celebrityFormat.length === 0) {
      toast({
        status: "error",
        title: "Ошибка",
        description: "Кол-во моделей должно быть больше 0",
      });
      return;
    }

    setIsLoading(true);

    BotService.addParsing({ modelId, celebrities: celebrityFormat })
      .then(res => {
        if (!res)
          toast({
            status: "error",
            title: "Ошибка",
            description: "Произошла ошибка при добавлении моделей для парсинга",
          });
        else {
          toast({
            status: "success",
            title: "Успех",
            duration: 10000,
            description: "Парсинг фоловеров добавлен в очередь. Рассылка будет остановлена, пока парсинг будет активен. По завершению процесса статус будет возвращен в текущее состояние",
          });
          closeHandler();
          onSuccess()
        }
      }).catch(() => {
        toast({
          status: "error",
          title: "Ошибка",
          description: "Произошла ошибка при добавлении моделей для парсинга",
        });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <Tooltip label="Добавить моделей для парсинга фоловеров">
        <IconButton aria-label="Добавить сообщения для рассылки" onClick={onOpen} size="sm"
          isLoading={isLoading}><AttachmentIcon /></IconButton>
      </Tooltip>
      <Modal isOpen={isOpen} onClose={closeHandler} returnFocusOnClose={false}>
        <ModalOverlay />
        <ModalContent className="min-w-[700px]">
          <ModalHeader className="!text-2xl">Добавление парсинга</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel className="!text-xl !flex !items-center justify-between">
                <Box className="flex items-center gap-2">
                  <Text>Модели для парсинга</Text>
                  <Tooltip label="Список модлей. Разделитель - Enter">
                    <InfoIcon />
                  </Tooltip>
                </Box>
                <Text>Кол-во моделей: {celebrityFormat.length}</Text>
              </FormLabel>
              <Textarea
                minH={200}
                focusBorderColor="teal.200"
                className="!text-xl"
                value={celebrity}
                onChange={(e) => setCelebrity(e.target.value)}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button onClick={closeHandler} mr={3} variant="ghost">Отмена</Button>
            <Button onClick={onSubmit} isLoading={isLoading}>Добавить</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddParsing;
