import { FC, useState } from "react";
import {
  Button,
  ButtonGroup,
  IconButton, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverFooter, PopoverHeader,
  PopoverTrigger, Text, useDisclosure, useToast,
} from "@chakra-ui/react";
import { RepeatIcon } from "@chakra-ui/icons";
import { BotService } from "../services/bot.service";

const DeleteModel: FC<{ username: string, id: number, onSuccess: () => void }> = ({ username, id, onSuccess }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const deleteHandler = () => {
    setIsLoading(true);
    BotService.clearFollowers(id)
      .then(() => {
        onSuccess()
        onClose()
      })
      .catch(() => {
        toast({
          status: "error",
          title: "Ошибка",
          description: "Произошла ошибка при очистке аккаунтов для рассылки",
        });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Popover placement="left" isOpen={isOpen} onClose={onClose} onOpen={onOpen}>
      <PopoverTrigger>
        <IconButton aria-label="Очистить followers модели" icon={<RepeatIcon />} size="sm" />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader className="font-bold !text-lg text-center">Подтверждение</PopoverHeader>
        <PopoverBody textAlign="center" className="flex items-center justify-center">
          <Text className="text-center break-words text-wrap max-w-max">Очистить аккаунты для рассылки модели {username}?</Text>
        </PopoverBody>
        <PopoverFooter>
          <ButtonGroup size="md" justifyContent="space-between" w="100%">
            <Button onClick={onClose} mr={3} variant="ghost">Отмена</Button>
            <Button isLoading={isLoading} onClick={deleteHandler}>Да, очистить</Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};

export default DeleteModel;
