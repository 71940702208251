import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAdmin } from './types';

const initialState: { admins: IAdmin[] } = {
  admins: []
}

const adminsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setAdmins(state, payload: PayloadAction<IAdmin[]>) {
      state.admins = payload.payload
    }
  }
})

export const { setAdmins } = adminsSlice.actions

export default adminsSlice.reducer
