import axios from '../axios';
import { getDonateUrl } from '../config/api.config';
import { GroupedResponse, UnGroupedResponse } from '../models/IDonate';

export const DonateService = {
    async getAll({ grouped, buyer, limit, page, type, shift, model }: {
        grouped: boolean,
        type?: string,
        shift?: string,
        model?: string,
        buyer?: string,
        limit?: number,
        page?: number
    }) {
        const searchParams = new URLSearchParams();
        searchParams.set('grouped', `${grouped}`);
        if (limit)
            searchParams.set('limit', `${limit}`);
        if (page)
            searchParams.set('page', `${page}`);
        if (type)
            searchParams.set('type', type);
        if (shift)
            searchParams.set('shift', shift);
        if (model)
            searchParams.set('model', model);
        if (buyer)
            searchParams.set('buyer', buyer);


        const { data } = await axios.get<UnGroupedResponse | GroupedResponse>(getDonateUrl(`/optimized?${searchParams.toString()}`));
        return data;
    },
    async setComment(buyerId: string, comment: string) {
        const { status } = await axios.patch(getDonateUrl(`/${buyerId}`), { comment });

        return status === 200;
    },
    async startParse() {
        const { status } = await axios.get(getDonateUrl('/parse'));
        return status === 200;
    },
};
