import ReactDOM from "react-dom/client";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from "./pages/Login";
import theme from "./theme";
import { Provider } from "react-redux";
import { store } from "./store/store";
import Home from "./pages/Home";
import Layout from "./components/Layout";
import NotFound from "./pages/NotFound";
import Models from "./pages/Models";
import Operators from "./pages/Operators";


const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
    errorElement: <NotFound />,
  },
  {
    path: "/",
    element: <Layout><Home /></Layout>,
    errorElement: <NotFound />,
  },
  {
    path: "/models",
    element: <Layout><Models /></Layout>,
    errorElement: <NotFound />,
  },
  {
    path: "/operators",
    element: <Layout><Operators /></Layout>,
    errorElement: <NotFound />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <ChakraProvider theme={theme}>
    {/*<ChakraProvider theme={theme}>*/}
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </ChakraProvider>,
  // </ChakraProvider>,
);
