import { FC } from "react";
import {
  Button, IconButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Table,
  TableContainer, Tag, Tbody, Td, Th, Thead, Tooltip, Tr, useDisclosure,
} from "@chakra-ui/react";
import { ChatIcon } from "@chakra-ui/icons";
import { IMessage } from "../models/IMessage";
import cn from "classnames";

type Props = {
  messages?: IMessage
  packCount: number
}

const ShowMessages: FC<Props> = ({ messages, packCount }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const isFinished = messages?.messages.reduce((sum, item) => sum + item.recipients.length, 0) === packCount;

  return (
    <>
      <Tooltip label="Сообщения">
        <IconButton aria-label="Показать сообщения" onClick={onOpen} size="sm"><ChatIcon /></IconButton>
      </Tooltip>
      <Modal isOpen={isOpen} onClose={onClose} returnFocusOnClose={false}>
        <ModalOverlay />
        <ModalContent className="min-w-[800px] max-lg:min-w-[90vw]">
          <ModalHeader className="!text-2xl">Сообщения</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TableContainer overflowY="auto" className="max-h-[90vh]">
              <Table>
                <Thead>
                  <Tr>
                    <Th>Сообщение</Th>
                    <Th>Статус</Th>
                    <Th>Получатели</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {messages?.messages.sort((a, b) => a.id - b.id).map(item => <Tr key={item.id}>
                    <Td>{item.text}</Td>
                    <Td className={cn({
                      "text-green-400": isFinished,
                      "text-yellow-400": !isFinished,
                    })}>{isFinished ? "Доставлено" : "В процессе"}<Tag
                      rounded="full" ml={3}>{item.recipients.length}</Tag></Td>
                    <Td>{item.recipients.join(", ")}</Td>
                  </Tr>)}
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Закрыть</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ShowMessages;
