import { FC, useState } from "react";
import {
  Box, Button, ButtonGroup, FocusLock, FormControl, FormLabel, Icon, IconButton, InputGroup, InputRightElement,
  NumberInput, NumberInputField, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverFooter, PopoverHeader,
  PopoverTrigger, Text, Tooltip, useDisclosure, useToast,
} from "@chakra-ui/react";
import { InfoIcon, SettingsIcon, TimeIcon } from "@chakra-ui/icons";
import { ModelService } from "../services/model.service";

type Props = {
  onSuccessSmsTimeOut: (smsTimeout: number) => void
  onSuccessPackTimeOut: (packTimeout: number) => void
  onSuccessPackCount: (packCount: number) => void
}

const ApplyForAll: FC<Props> = ({ onSuccessSmsTimeOut, onSuccessPackTimeOut, onSuccessPackCount }) => {
  const [smsTimeout, setSmsTimeout] = useState("1");
  const [packTimeOut, setPackTimeOut] = useState("1");
  const [packCount, setPackCount] = useState("1");

  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const closeHandler = () => {
    onClose()
    setPackCount("1");
    setPackTimeOut("1");
    setSmsTimeout("1");
  };

  const onApplySmsTimeout = () => {
    setIsLoading(true);
    ModelService.setSmsTimeoutForAll(+smsTimeout)
      .then(() => onSuccessSmsTimeOut(+smsTimeout))
      .catch(() => {
        toast({
          status: "error",
          title: "Ошибка",
          description: "Произошла ошибка при обновлении смс задержки",
        });
      })
      .finally(() => setIsLoading(false));
  };

  const onApplyPackTimeout = () => {
    setIsLoading(true);
    ModelService.setPackTimeoutForAll(+packTimeOut)
      .then(() => onSuccessPackTimeOut(+packTimeOut))
      .catch(() => {
        toast({
          status: "error",
          title: "Ошибка",
          description: "Произошла ошибка при обновлении задержки группы",
        });
      })
      .finally(() => setIsLoading(false));
  };

  const onApplyPackCount = () => {
    setIsLoading(true);
    ModelService.setPackCountForAll(+packCount)
      .then(() => onSuccessPackCount(+packCount))
      .catch(() => {
        toast({
          status: "error",
          title: "Ошибка",
          description: "Произошла ошибка при обновлении размера группы",
        });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={closeHandler}>
      <PopoverTrigger>
        <IconButton aria-label="Применить для всех" isLoading={isLoading}><SettingsIcon /></IconButton>
      </PopoverTrigger>
      <PopoverContent className="min-w-[400px]">
        <FocusLock restoreFocus persistentFocus={false}>
          <PopoverArrow />
          <PopoverHeader className="!text-lg font-bold">Общие настройки</PopoverHeader>
          <PopoverBody>
            <FormControl className="mt-4">
              <FormLabel className="!flex items-center gap-2">
                <Text>Смс задержка</Text>
                <Tooltip label="Частота отправки смс. Измеряется в секундах">
                  <InfoIcon />
                </Tooltip>
              </FormLabel>
              <Box className="flex gap-2 items-center justify-between">
                <InputGroup>
                  <NumberInput focusBorderColor="teal.200"
                    min={1} value={smsTimeout} onChange={setSmsTimeout} allowMouseWheel
                    clampValueOnBlur>
                    <NumberInputField />
                    <InputRightElement className="flex items-center justify-center !h-full">
                      <TimeIcon />
                    </InputRightElement>
                  </NumberInput>
                </InputGroup>
                <Button size="md" className="w-full" onClick={onApplySmsTimeout} isLoading={isLoading}>Применить для
                  всех</Button>
              </Box>
            </FormControl>
            <FormControl className="mt-4">
              <FormLabel className="!flex items-center gap-2">
                <Text>Размер группы смс</Text>
                <Tooltip label="Количество смс после которого будет установлена задержка">
                  <InfoIcon />
                </Tooltip>
              </FormLabel>
              <Box className="flex gap-2 items-center justify-between">
                <InputGroup>
                  <NumberInput focusBorderColor="teal.200"
                    min={1} value={packCount} onChange={setPackCount} allowMouseWheel
                    clampValueOnBlur>
                    <NumberInputField />
                    <InputRightElement className="flex items-center justify-center !h-full">
                      <Icon viewBox="0 0 24 24" className="fill-white">
                        <path
                          d="M21 3C21.5523 3 22 3.44772 22 4V18C22 18.5523 21.5523 19 21 19H6.455L2 22.5V4C2 3.44772 2.44772 3 3 3H21ZM20 5H4V18.385L5.76333 17H20V5ZM13 7V15H11V7H13ZM17 9V15H15V9H17ZM9 11V15H7V11H9Z" />
                      </Icon>
                    </InputRightElement>
                  </NumberInput>
                </InputGroup>
                <Button size="md" className="w-full" onClick={onApplyPackCount} isLoading={isLoading}>Применить для
                  всех</Button>
              </Box>
            </FormControl>
            <FormControl className="mt-4">
              <FormLabel className="!flex items-center gap-2">
                <Text>Задержка группы</Text>
                <Tooltip label="Задержка после отправки группы смс. Измеряется в минутах">
                  <InfoIcon />
                </Tooltip>
              </FormLabel>
              <Box className="flex gap-2 items-center justify-between">
                <InputGroup>
                  <NumberInput focusBorderColor="teal.200" className="w-full"
                    min={1} value={packTimeOut} onChange={setPackTimeOut} allowMouseWheel
                    clampValueOnBlur>
                    <NumberInputField />
                    <InputRightElement className="flex items-center justify-center !h-full">
                      <TimeIcon />
                    </InputRightElement>
                  </NumberInput>
                </InputGroup>
                <Button size="md" className="w-full" onClick={onApplyPackTimeout} isLoading={isLoading}>Применить для
                  всех</Button>
              </Box>
            </FormControl>
          </PopoverBody>
          <PopoverFooter>
            <ButtonGroup size="md" justifyContent="space-between" w="100%">
              <Button onClick={closeHandler} mr={3} variant="ghost">Закрыть</Button>
            </ButtonGroup>
          </PopoverFooter>
        </FocusLock>
      </PopoverContent>
    </Popover>
  );
};

export default ApplyForAll;
