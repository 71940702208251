import axios from "../axios";
import { getBotUrl } from "../config/api.config";

export const BotService = {
  async setSettings(dto: { timeout: number }) {
    const { status } = await axios.post(getBotUrl('/settings'), dto)

    return status === 201
  },
  async getSettings() {
    const { data } = await axios.get<{ timeout: number, isPaused: boolean, maxActiveModels: number, activeModels: number }>(getBotUrl('/settings'))
    return data
  },
  async startBot() {
    const { status } = await axios.get(getBotUrl('/resume'))
    return status === 200
  },
  async stopBot() {
    const { status } = await axios.get(getBotUrl('/pause'))
    return status === 200
  },
  async addParsing(data: { modelId: number, celebrities: string[] }) {
    const { status } = await axios.post(getBotUrl('/parsing'), data)
    return status === 201
  },
  async clearFollowers(modelId: number) {
    const { status } = await axios.delete(getBotUrl(`/parsing/${modelId}`))
    return status === 200
  },
}
